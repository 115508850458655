import React from 'react';
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { useUserTracking } from '@/core/hooks/useUserTracking';
import { errorHandler } from '@/core/libs/error-handler';
import { getManufacturersByUrl } from '@/modules/matchmaking/services/get-manufacturers-by-url';
import {
  searchableCertificates,
  searchableMaterials,
  searchableTechnologies,
} from '@/modules/matchmaking/utils/factories/SearchableConfigurationFactory';
import {
  FeedElementDto,
  ProjectsService,
  SearchMachineDto,
} from '@/generated/api';

import {
  FEED_REDUCER_ACTION_TYPE,
  feedFiltersReducer,
  feedFiltersReducerInitialState,
  FeedFiltersReducerType,
} from '../reducer/feed-filters-reducer';

export type FilterRange = {
  countryCodes: string[];
  minWorkpieces: number;
  maxWorkpieces: number;
  minLotSizes: number;
  maxLotSizes: number;
};

export const FilterType = {
  machines: 'machines',
  technologies: 'technologies',
  materials: 'materials',
  workpieces: 'workpieces',
  lotSizes: 'lotSizes',
  certifications: 'certifications',
  countryCodes: 'countryCodes',
  incoterms: 'incoterms',
} as const;
export type FilterType = (typeof FilterType)[keyof typeof FilterType];

export type FeedContextType = {
  isLoading: boolean;
  viewMode: 'table' | 'cards';
  setViewMode: (newViewMode: 'table' | 'cards') => void;
  filterRanges: FilterRange;
  allProjects: FeedElementDto[];
  fetchNextPage: () => void;
  paginatedProjects: FeedElementDto[];
  clearAllFilters: () => void;
  clearFiltersDisabled: boolean;
  applyOverrideWithAI: () => void;
  filters: FeedFiltersReducerType;
  dispatchFilters: React.Dispatch<FEED_REDUCER_ACTION_TYPE>;
  filtersCount: number;
  filtersToSort: FilterType[];
  setFiltersToSort: React.Dispatch<React.SetStateAction<FilterType[]>>;
  showAddMachineHint: boolean;
  setShowAddMachineHint: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProjectIdContact: number;
  setSelectedProjectIdContact: React.Dispatch<React.SetStateAction<number>>;
  selectedProjectNdaModal?: FeedElementDto;
  setSelectedProjectNdaModal: React.Dispatch<
    React.SetStateAction<FeedElementDto | undefined>
  >;
  updateProject: (
    projectId: number,
    updates: Partial<FeedElementDto>,
  ) => FeedElementDto | undefined;
};

export const FeedContext = React.createContext<FeedContextType>({
  isLoading: false,
  viewMode:
    localStorage.getItem('feed:viewMode') === 'table' ? 'table' : 'cards',
  setViewMode: () => {},
  filterRanges: {
    countryCodes: [],
    minWorkpieces: 1,
    maxWorkpieces: 1,
    minLotSizes: 1,
    maxLotSizes: 1,
  },
  allProjects: [],
  fetchNextPage: () => {},
  paginatedProjects: [],
  clearAllFilters: () => {},
  clearFiltersDisabled: true,
  applyOverrideWithAI: () => {},
  filters: feedFiltersReducerInitialState,
  dispatchFilters: () => {},
  filtersCount: 0,
  filtersToSort: [],
  setFiltersToSort: () => {},
  showAddMachineHint: false,
  setShowAddMachineHint: () => {},
  selectedProjectIdContact: 0,
  setSelectedProjectIdContact: () => {},
  setSelectedProjectNdaModal: () => {},
  updateProject: () => undefined,
});

export function FeedProvider({ children }: { children?: React.ReactNode }) {
  const { isLoggedIn, currentUser } = useAuthenticationContext();
  const { trackUserEvent, TrackerEvents } = useUserTracking();
  const [viewMode, setViewMode] = React.useState<'table' | 'cards'>(
    localStorage.getItem('feed:viewMode') === 'table' ? 'table' : 'cards',
  );

  const [filtersToSort, setFiltersToSort] = React.useState<FilterType[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] =
    React.useState<boolean>(false);
  const [filterRanges, setFilterRanges] = React.useState<FilterRange>({
    countryCodes: [],
    minWorkpieces: 1,
    maxWorkpieces: 1,
    minLotSizes: 1,
    maxLotSizes: 1,
  });
  const [showAddMachineHint, setShowAddMachineHint] =
    React.useState<boolean>(false);

  const [filters, dispatchFilters] = React.useReducer(
    feedFiltersReducer,
    feedFiltersReducerInitialState,
  );

  /** Used to show Contact Detail modal */
  const [selectedProjectIdContact, setSelectedProjectIdContact] =
    React.useState<number>(0);
  /** Used to show sign NDA modal */
  const [selectedProjectNdaModal, setSelectedProjectNdaModal] =
    React.useState<FeedElementDto>();

  const {
    showExpired,
    sort,
    technologyIds,
    certificationsIds,
    incoterms,
    countryCodes,
    workpieces,
    lotSizes,
    materialIds,
    machines,
    pagination,
  } = filters;

  const applyOverrideWithAI = React.useCallback(async () => {
    if (!currentUser) return;
    try {
      const res = await getManufacturersByUrl({
        partfox_id: currentUser?.company?.id,
      });
      const { manufacturer } = res;

      if (
        localStorage.getItem('feed:showAddMachineHint') === 'false' &&
        manufacturer.machines.length === 0
      ) {
        setShowAddMachineHint(true);
      }
      const manTechnologies = manufacturer.technologyIds?.filter((t) =>
        searchableTechnologies.map((st) => st.id).includes(t),
      );
      const manMaterials = manufacturer.materialIds?.filter((m) =>
        searchableMaterials.map((sm) => sm.id).includes(m),
      );
      const manCertifications = manufacturer.certificationIds?.filter((c) =>
        searchableCertificates.map((sc) => sc.id).includes(c),
      );

      const showSparksForMachines =
        manufacturer.machines && manufacturer.machines.length > 0;
      const showSparksForMaterials = manMaterials && manMaterials.length > 0;
      const showSparksForTechnologies =
        manTechnologies && manTechnologies.length > 0;
      const showSparksForCertifications = (manCertifications &&
        manCertifications.length > 0) as boolean;

      const newFiltersToSort: FilterType[] = [];
      if (showSparksForMachines) newFiltersToSort.push(FilterType.machines);
      if (showSparksForMaterials) newFiltersToSort.push(FilterType.materials);
      if (showSparksForTechnologies)
        newFiltersToSort.push(FilterType.technologies);
      if (showSparksForCertifications)
        newFiltersToSort.push(FilterType.certifications);

      setFiltersToSort([...filtersToSort, ...newFiltersToSort]);

      dispatchFilters({
        type: 'SET_VALUES_WITH_AI',
        payload: {
          machines:
            manufacturer.machines.length > 0
              ? manufacturer.machines
              : filters.machines,
          materialIds:
            manMaterials && manMaterials.length > 0
              ? manMaterials
              : filters.materialIds,
          technologyIds:
            manTechnologies && manTechnologies.length > 0
              ? manTechnologies
              : filters.technologyIds,
          certificationsIds:
            manCertifications && manCertifications.length > 0
              ? manCertifications
              : filters.certificationsIds,
          showSparksForMachines,
          showSparksForMaterials,
          showSparksForTechnologies,
          showSparksForCertifications,
        },
      });
      trackUserEvent(TrackerEvents.FEED_AI_MATCHING_APPLIED);
    } catch (error) {
      errorHandler.capture(error);
    }
  }, [
    TrackerEvents.FEED_AI_MATCHING_APPLIED,
    currentUser,
    filters.certificationsIds,
    filters.machines,
    filters.materialIds,
    filters.technologyIds,
    filtersToSort,
    trackUserEvent,
  ]);

  const clearAllFilters = React.useCallback(() => {
    dispatchFilters({
      type: 'RESET_FILTERS',
      payload: {
        workpieces: [filterRanges.minWorkpieces, filterRanges.maxWorkpieces],
        lotSizes: [filterRanges.minLotSizes, filterRanges.maxLotSizes],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterRanges.maxLotSizes,
    filterRanges.maxWorkpieces,
    filterRanges.minLotSizes,
    filterRanges.minWorkpieces,
  ]);

  const clearFiltersDisabled = React.useMemo(
    () =>
      machines.length === 0 &&
      materialIds.length === 0 &&
      technologyIds.length === 0 &&
      certificationsIds.length === 0 &&
      incoterms.length === 0 &&
      countryCodes.length === 0 &&
      workpieces[0] === filterRanges.minWorkpieces &&
      workpieces[1] === filterRanges.maxWorkpieces &&
      lotSizes[0] === filterRanges.minLotSizes &&
      lotSizes[1] === filterRanges.maxLotSizes,
    [
      certificationsIds.length,
      countryCodes.length,
      filterRanges.maxLotSizes,
      filterRanges.maxWorkpieces,
      filterRanges.minLotSizes,
      filterRanges.minWorkpieces,
      incoterms.length,
      lotSizes,
      machines.length,
      materialIds.length,
      technologyIds.length,
      workpieces,
    ],
  );

  const filtersCount = React.useMemo(() => {
    let count = 0;
    if (machines.length > 0) count += 1;
    if (technologyIds.length > 0) count += 1;
    if (materialIds.length > 0) count += 1;
    if (certificationsIds.length > 0) count += 1;
    if (countryCodes.length > 0) count += 1;
    if (incoterms.length > 0) count += 1;
    if (
      workpieces[0] !== filterRanges.minWorkpieces ||
      workpieces[1] !== filterRanges.maxWorkpieces
    )
      count += 1;
    if (
      lotSizes[0] !== filterRanges.minLotSizes ||
      lotSizes[1] !== filterRanges.maxLotSizes
    )
      count += 1;
    return count;
  }, [
    certificationsIds.length,
    countryCodes.length,
    filterRanges.maxLotSizes,
    filterRanges.maxWorkpieces,
    filterRanges.minLotSizes,
    filterRanges.minWorkpieces,
    incoterms.length,
    lotSizes,
    machines.length,
    materialIds.length,
    technologyIds.length,
    workpieces,
  ]);

  const fetchPaginatedSearch = isLoggedIn
    ? ProjectsService.projectsControllerPaginatedSearch
    : ProjectsService.projectsControllerPublicPaginatedSearch;

  const queryFn = async ({ pageParam }: { pageParam?: number }) => {
    const response = await fetchPaginatedSearch(
      pageParam || pagination.currentPage,
      viewMode === 'table' ? pagination.pageSize : 10,
      sort === 'newest' ? showExpired : false,
      sort,
      workpieces[0] !== filterRanges.minWorkpieces ? workpieces[0] : undefined,
      workpieces[1] !== filterRanges.maxWorkpieces ? workpieces[1] : undefined,
      lotSizes[0] !== filterRanges.minLotSizes ? lotSizes[0] : undefined,
      lotSizes[1] !== filterRanges.maxLotSizes ? lotSizes[1] : undefined,
      technologyIds,
      materialIds,
      certificationsIds,
      incoterms,
      countryCodes,
      machines.length > 0
        ? (JSON.stringify(machines) as unknown as SearchMachineDto[])
        : undefined,
    );
    dispatchFilters({
      type: 'SET_PAGINATION',
      payload: {
        pageSize: response.meta.itemsPerPage,
        currentPage: response.meta.currentPage,
        totalItems: response.meta.totalItems,
        totalPages: response.meta.totalPages,
      },
    });

    // @ts-ignore
    return response.items as FeedElementDto[];
  };

  const queryKey = React.useMemo(
    () => [
      isLoggedIn,
      pagination.pageSize,
      sort,
      showExpired,
      workpieces[0],
      filterRanges.minWorkpieces,
      workpieces[1],
      filterRanges.maxWorkpieces,
      lotSizes[0],
      filterRanges.minLotSizes,
      lotSizes[1],
      filterRanges.maxLotSizes,
      technologyIds,
      materialIds,
      certificationsIds,
      incoterms,
      countryCodes,
      machines.length,
      machines,
    ],
    [
      isLoggedIn,
      pagination.pageSize,
      sort,
      showExpired,
      workpieces,
      filterRanges.minWorkpieces,
      filterRanges.maxWorkpieces,
      filterRanges.minLotSizes,
      filterRanges.maxLotSizes,
      lotSizes,
      technologyIds,
      materialIds,
      certificationsIds,
      incoterms,
      countryCodes,
      machines,
    ],
  );

  const { data: paginatedProjects, isFetching: isLoadingPaginated } = useQuery({
    enabled: viewMode === 'table',
    queryKey: ['feedData', ...queryKey, pagination.currentPage],
    // @ts-ignore - b/c pageParam is only defined with useInfiniteQuery
    queryFn,
    initialData: [],
    placeholderData: keepPreviousData,
  });

  const {
    data: allProjects,
    isLoading: isLoadingAllProjects,
    fetchNextPage,
  } = useInfiniteQuery({
    enabled: viewMode === 'cards',
    queryKey: ['feedDataInfinite', ...queryKey],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, lastPageParam) => lastPageParam + 1,
  });

  React.useEffect(() => {
    const getFilterRanges = async () => {
      try {
        if (isLoadingFilters) return;
        setIsLoadingFilters(true);
        const ranges =
          await ProjectsService.projectsControllerGetFeedFilterRanges();
        setFilterRanges({
          countryCodes: ranges.countryCodes ?? [],
          minWorkpieces: ranges.minWorkpieces ?? 1,
          maxWorkpieces: ranges.maxWorkpieces ?? 1,
          minLotSizes: ranges.minLotSizes ?? 1,
          maxLotSizes: ranges.maxLotSizes ?? 1,
        });
        dispatchFilters({
          type: 'SET_WORKPIECES',
          payload: [ranges.minWorkpieces ?? 1, ranges.maxWorkpieces ?? 1],
        });
        dispatchFilters({
          type: 'SET_LOT_SIZES',
          payload: [ranges.minLotSizes ?? 1, ranges.maxLotSizes ?? 1],
        });
      } catch (error) {
        errorHandler.capture(error, { avoidFlashMessage: true });
      } finally {
        setIsLoadingFilters(false);
      }
    };
    getFilterRanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();

  const updateProject = React.useCallback(
    (
      projectId: number,
      updates: Partial<FeedElementDto>,
    ): FeedElementDto | undefined => {
      let updatedProject: FeedElementDto | undefined;

      if (viewMode === 'table') {
        // Update project in table view
        queryClient.setQueryData(
          ['feedData', ...queryKey, pagination.currentPage],
          (oldData: FeedElementDto[] | undefined) => {
            if (!oldData) return [];
            return oldData.map((project) => {
              if (project.projectId === projectId) {
                updatedProject = { ...project, ...updates };
                return updatedProject;
              }
              return project;
            });
          },
        );
      } else {
        // Update project in cards view
        queryClient.setQueryData(
          ['feedDataInfinite', ...queryKey],
          (oldData: InfiniteData<FeedElementDto[]> | undefined) => {
            if (!oldData) return { pages: [], pageParams: [] };
            return {
              ...oldData,
              pages: oldData.pages.map((page: FeedElementDto[]) =>
                page.map((project) => {
                  if (project.projectId === projectId) {
                    updatedProject = { ...project, ...updates };
                    return updatedProject;
                  }
                  return project;
                }),
              ),
            };
          },
        );
      }

      return updatedProject;
    },
    [viewMode, queryKey, pagination.currentPage, queryClient],
  );

  const value = React.useMemo(
    () => ({
      isLoading:
        viewMode === 'table' ? isLoadingPaginated : isLoadingAllProjects,
      viewMode,
      setViewMode,
      filterRanges,
      allProjects: allProjects?.pages.flat() ?? [],
      fetchNextPage,
      paginatedProjects,
      pagination,
      sort,
      technologyIds,
      certificationsIds,
      incoterms,
      countryCodes,
      workpieces,
      lotSizes,
      clearAllFilters,
      clearFiltersDisabled,
      applyOverrideWithAI,
      filters,
      dispatchFilters,
      filtersCount,
      filtersToSort,
      setFiltersToSort,
      showAddMachineHint,
      setShowAddMachineHint,
      selectedProjectIdContact,
      setSelectedProjectIdContact,
      selectedProjectNdaModal,
      setSelectedProjectNdaModal,
      updateProject,
    }),
    [
      viewMode,
      isLoadingPaginated,
      isLoadingAllProjects,
      filterRanges,
      allProjects?.pages,
      fetchNextPage,
      paginatedProjects,
      pagination,
      sort,
      technologyIds,
      certificationsIds,
      incoterms,
      countryCodes,
      workpieces,
      lotSizes,
      clearAllFilters,
      clearFiltersDisabled,
      applyOverrideWithAI,
      filters,
      filtersCount,
      filtersToSort,
      showAddMachineHint,
      selectedProjectIdContact,
      selectedProjectNdaModal,
      updateProject,
    ],
  );

  return (
    <FeedContext.Provider value={value}>
      {children || <Outlet />}
    </FeedContext.Provider>
  );
}
FeedContext.displayName = 'FeedContext';

export const useFeedContext = () => React.useContext(FeedContext);
